import React, { useState } from 'react'
import { Button, Form, FormGroup, Input, InputGroupAddon, InputGroup, ButtonGroup, Label, Alert } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrash, faClipboard } from '@fortawesome/free-solid-svg-icons'

import { clientsRegEx } from '../utils/validations'
import EditModal from './EditModal'
import { clientsCorrectForm, getAuthorizationFromLocal } from '../utils/utilities'
import Swal from 'sweetalert2'


function CustomRow({ email, password, admin, clients, update, fbId, clientCompanies, setClientCompanies, setDelete, role, super_admin, usersToDelete, silentUpdate, view_only, first_name, last_name }) {

    const currentEmail = getAuthorizationFromLocal()[0].email

    const [clientErr, setClientErr] = useState('')

    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(prevState => !prevState)

    const updateUser = async (e) => {
        e.preventDefault()
        const { value, name } = e.target[0] //ref to input, so i dont have to create states     

        if (((role.admin || role.super_admin) && (!role.clients || (role.clients && role.clients === ''))) && name === 'clients' && value !== '' && !clientsRegEx.test(value)) {
            setClientErr('Only numbers and commas')
            return
        }

        if ((!role.client_admin) && (name === 'admin' || name === 'super_admin') && (value === true || value === 'true')) {
            if (view_only) {
                await silentUpdate(
                    fbId,
                    'view_only',
                    false
                )
            }
        } else if ((!role.client_admin) && (name === 'admin' || name === 'super_admin') && (value === false || value === 'false')) {
            if (!view_only) {
                await silentUpdate(
                    fbId,
                    'view_only',
                    true
                )
            }
        }
        await update(
            fbId,
            name,
            name === 'clients'
                ? clientsCorrectForm(value)
                : value === 'false'
                    ? false
                    : value === 'true'
                        ? true
                        : name === 'first_name' || name === 'last_name'
                            ? value[0].toUpperCase() + value.substring(1)
                            : value)

        //select input transforms booleans to string so i have to reverse it before submitting
        if (name !== 'admin' && name !== 'super_admin' && name !== 'client_admin') {
            const inp = document.getElementById(name)
            inp.value = ''
        }

        setClientErr('')
    }

    // const updatePassword = async (e) => {
    //     e.preventDefault()
    //     const newPassword = passwordGen.generate({
    //         length: 20,
    //         numbers: true,
    //     })
    //     await update(
    //         fbId,
    //         'password',
    //         newPassword
    //     ) //select input transforms booleans to string so i have to reverse it before submitting
    // }

    const handleToggle = (e) => {
        const id = e.target.value

        const ix = clientCompanies.findIndex(e => e.clientId.toString() === id)
        if (ix === -1) return
        let companies = [...clientCompanies]
        companies[ix].selected = !companies[ix].selected
        setClientCompanies(companies)
    }

    const updateClients = async (e) => {
        e.preventDefault()
        let clientsSelected = false
        let idArr = []
        clientCompanies.forEach((e) => {
            if (e.selected) {
                idArr.push(e.clientId)
                clientsSelected = true
            }
        })
        if (!clientsSelected) {
            setClientErr('Select at least one')
            return
        }
        await update(
            fbId,
            'clients',
            idArr.join(',')
        )
        setClientErr('')
    }

    const isUserInDeletedList = usersToDelete.find((user) => user.key === fbId)
        ? true
        : false

    const entitiesOrClients = () => {
        if (role.clients && role.clients !== '') {
            let matchClients = []
            clients.split(',').forEach(e => {
                const entity = clientCompanies.find(c => c.clientId.toString() === e)
                if (entity) matchClients.push(entity.name)
            })
            return {
                clients: matchClients
            }
        } else {
            return clients
        }
    }


    return (
        <>
            <tr className={`pointer ${isUserInDeletedList ? 'text-danger' : ''}`}>
                <td>
                    <CustomCell attribute={email} toggle={toggle} role={role} />
                </td>
                <td>
                    <CustomCell attribute={last_name && first_name ? last_name + ', ' + first_name : ''} toggle={toggle} role={role} />
                </td>
                <td>
                    <CustomCell attribute={password} toggle={toggle} role={role} />
                </td>
                <td
                    className="text-center">
                    <CustomCell attribute={admin} toggle={toggle} role={role} />
                </td>
                {
                    role.super_admin && (
                        clients
                            ? <td></td>
                            : <td
                                className="text-center">
                                <CustomCell attribute={super_admin} toggle={toggle} role={role} />
                            </td>
                    )
                }
                <td>
                    {clients
                        ? <CustomCell attribute={entitiesOrClients()} clientCompanies={clientCompanies} toggle={toggle} role={role} />
                        : <></>
                    }
                </td>
                <td className='text-center'>
                    <ButtonGroup>
                        <Button
                            onClick={() => {
                                if (role.super_admin) {
                                    toggle()
                                    return
                                }
                                const comps = [...clientCompanies]
                                for (let i = 0; i < comps.length; i++) {
                                    comps[i] = {
                                        ...comps[i],
                                        selected: clients.split(',').includes(comps[i].clientId.toString())
                                            ? true
                                            : false
                                    }
                                }
                                setClientCompanies(comps)
                                toggle()
                            }}
                            color='primary'
                        >
                            <FontAwesomeIcon icon={faPencilAlt} size='1x' />
                        </Button>
                        <Button
                            onClick={() => {
                                if (role.super_admin || (role.admin && currentEmail !== email)) setDelete({ email: email, key: fbId })
                                else { Swal.fire('Do not have required authorization', '', 'error') }
                            }}
                            color='danger'
                        >
                            <FontAwesomeIcon icon={faTrash} size='1x' />
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
            <EditModal isOpen={isOpen} toggle={toggle} >

                <br></br>
                {
                    (role.admin || role.super_admin || role.client_admin) && ( //edit Row
                        <>
                            <Form onSubmit={updateUser}>
                                <FormGroup>
                                    <InputGroup>
                                        <span className='w-25'><Label>First Name: </Label></span>
                                        <Input
                                            className="text-center"
                                            required
                                            id='first_name'
                                            type={'text'}
                                            name={'first_name'}
                                            placeholder={first_name}
                                        />
                                        <InputGroupAddon addonType='append'>
                                            <Button
                                                color='warning'
                                                type='submit'
                                            >
                                                {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                Update
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                            <hr></hr>
                            <Form onSubmit={updateUser}>
                                <FormGroup>
                                    <InputGroup>
                                        <span className='w-25'><Label>Last Name: </Label></span>
                                        <Input
                                            className="text-center"
                                            required
                                            id='last_name'
                                            type={'text'}
                                            name={'last_name'}
                                            placeholder={last_name}
                                        />
                                        <InputGroupAddon addonType='append'>
                                            <Button
                                                color='warning'
                                                type='submit'
                                            >
                                                {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                Update
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                            <hr></hr>
                            <Form onSubmit={updateUser}>
                                <FormGroup>
                                    <InputGroup>
                                        <span className='w-25'><Label>Email: </Label></span>
                                        <Input
                                            className="text-center"
                                            id='email'
                                            type={'email'}
                                            name={'email'}
                                            placeholder={email}
                                            required
                                        />
                                        <InputGroupAddon addonType='append'>
                                            <Button
                                                color='warning'
                                                type='submit'
                                            >
                                                {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                Update
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                            <hr></hr>
                            <Form onSubmit={updateUser}>
                                <FormGroup>
                                    <InputGroup>
                                        <span className='w-25'><Label>Password: </Label></span>
                                        <Input
                                            className="text-center"
                                            required
                                            type={'text'}
                                            id='password'
                                            name={'password'}
                                            placeholder={password}
                                        />
                                        <InputGroupAddon addonType='append'>
                                            <Button
                                                color='warning'
                                                type='submit'
                                            >
                                                {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                Update
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                            </Form>
                            <hr></hr>
                            {email !== currentEmail && (role.admin || role.super_admin)
                                ? <>
                                    <Form onSubmit={updateUser}>
                                        <FormGroup>
                                            <InputGroup>
                                                <span className='w-25'><Label>Admin: </Label></span>
                                                <Input
                                                    type="select"
                                                    name={role.client_admin ? "client_admin" : "admin"}>
                                                    {admin
                                                        ? <>
                                                            <option value={true} >Yes</option>
                                                            <option value={false}>No</option>
                                                        </>
                                                        : <>
                                                            <option value={false}>No</option>
                                                            <option value={true} >Yes</option>
                                                        </>
                                                    }
                                                </Input>
                                                <InputGroupAddon addonType="append">
                                                    <Button color='warning' type='submit'>
                                                        {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                        Update
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                    <hr></hr>
                                </>
                                : <></>
                            }
                            {role.super_admin && (

                                <>
                                    <Form onSubmit={updateUser}>
                                        <FormGroup>
                                            <InputGroup>
                                                <span className='w-25'><Label>Superadmin: </Label></span>
                                                <Input
                                                    type="select"
                                                    name="super_admin">
                                                    {super_admin
                                                        ? <>
                                                            <option value={true} >Yes</option>
                                                            <option value={false}>No</option>
                                                        </>
                                                        : <>
                                                            <option value={false}>No</option>
                                                            <option value={true} >Yes</option>
                                                        </>
                                                    }
                                                </Input>
                                                <InputGroupAddon addonType="append">
                                                    <Button color='warning' type='submit'>
                                                        {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                        Update
                                                    </Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Form>
                                    <hr></hr>
                                </>
                            )}

                            {currentEmail !== email && (role.admin || role.super_admin)

                                ? !role.clients || (role.clients && role.clients === '')
                                    ? <Form onSubmit={updateUser}>
                                        {super_admin
                                            ? <></>
                                            : <>
                                                <FormGroup>
                                                    <InputGroup>
                                                        <span className='w-25'><Label>Clients: </Label></span>
                                                        <Input
                                                            className="text-center"
                                                            type={'text'}
                                                            id='clients'
                                                            name={'clients'}
                                                            placeholder={`${clients ? clients : ''} (numbers and commas only)`}
                                                        />
                                                        <InputGroupAddon addonType='append'>
                                                            <Button
                                                                type='submit'
                                                                color='warning'
                                                            >
                                                                {/* <FontAwesomeIcon icon={faEdit} size='1x' /> */}
                                                                Update
                                                            </Button>
                                                        </InputGroupAddon>
                                                    </InputGroup>
                                                </FormGroup>
                                                <p className='small_text ml-40px'>Leave empty and update for all clients</p>
                                                <p className='text-danger small_text ml-40px'>{clientErr}</p>
                                            </>
                                        }
                                    </Form>
                                    : <>
                                        <Alert color='secondary'>
                                            <h4>Select{(role.clients && role.clients !== '') ? ' Entities' : ' Clients'}:</h4>
                                            <hr></hr>
                                            <form className='d-flex flex-column' onSubmit={updateClients}>
                                                <fieldset className='d-flex flex-column align-items-start justify-content-start overflow-y-scroll max-h-150px'>
                                                    {clientCompanies.map((client, i) => {
                                                        return <label key={i}><input type={'checkbox'} onChange={handleToggle} defaultChecked={client.selected ? true : false} value={client.clientId} />{client.name}</label>
                                                    })}
                                                </fieldset>
                                                <div className='d-flex justify-content-end'>
                                                    <Button
                                                        color='warning'
                                                    >
                                                        Update {(role.clients && role.clients !== '') ? ' Entities' : ' Clients'}
                                                    </Button>
                                                </div>
                                                {role.admin && <p className='text-danger'>{clientErr}</p>}
                                            </form>
                                        </Alert>
                                    </>

                                : <></>
                            }
                            <br></br>
                        </>
                    )
                }
            </EditModal>
        </>
    )
}

function CustomCell({ attribute, role }) {
    const [isMouseOnCell, setIsMouseOnCell] = useState(false)

    return (
        <div className={`d-flex justify-content-between align-items-center`}
            onMouseEnter={() => setIsMouseOnCell(true)}
            onMouseLeave={() => setIsMouseOnCell(false)}
        >
            {typeof attribute === 'object' && role.clients && role.clients !== ''
                ? <div className='d-flex flex-column align-items-start'>
                    {attribute.clients.map((c) => {
                        return <span key={c}>{c.length > 25 ? c.substring(0, 25) + '...' : c}</span>
                    })}
                </div>
                : attribute === true
                    ? 'Yes'
                    : (attribute === false || attribute === undefined)
                        ? 'No'
                        : attribute.length > 29
                            ? attribute.substring(0, 30) + '...'
                            : attribute
            }
            {attribute === true || attribute === false || attribute === undefined
                ? <div className={`${isMouseOnCell ? 'opacity-1' : 'opacity-0'} smooth_transition ml-2`}>
                </div>
                : <div className={`${isMouseOnCell ? 'opacity-1' : 'opacity-0'} smooth_transition`}>
                    <ButtonGroup>
                        {!(typeof attribute === 'object')
                            ? <Button
                                onClick={() => window.navigator.clipboard.writeText(attribute)}
                            >
                                <FontAwesomeIcon icon={faClipboard} size='1x' />
                            </Button>
                            : <></>}
                    </ButtonGroup>
                </div>}
        </div>
    )
}


export default CustomRow