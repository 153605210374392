import { useEffect, useState } from "react"

import { getAuthorizationFromLocal, getRole, getUsersFromFirebase, matchUsersWithClientIds } from "../utils/utilities"


export const useAdminRole = (props) => {

    const [loading, setLoading] = useState(true)

    const [users, setUsers] = useState([])

    const [role, setRole] = useState(false)

    const clients = getAuthorizationFromLocal() ? getAuthorizationFromLocal()[0].clients : false

    const loadUsersFromFirebase = async () => {
        const users = await getUsersFromFirebase()
        if (!clients || clients === '') {
            setUsers(users)
        } else {
            setUsers(matchUsersWithClientIds(users))
        }

    }

    const authorization = getAuthorizationFromLocal()
    const currentEmail = authorization
        ? authorization[0].email
        : false

    useEffect(() => {
        setLoading(true)
        if (!authorization) {
            props.push({ pathname: `/` })
            return
        }
        getRole(currentEmail)
            .then((roleLvl) => {
                if (authorization && !(roleLvl.admin || roleLvl.super_admin || roleLvl.ycs_support)) {
                    props.push({ pathname: `/report/summary` })
                    return
                }
                setRole(roleLvl)
                if (!users.length) {
                    loadUsersFromFirebase()
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            )
    }, [])

    return { role, users, loadUsersFromFirebase, loading }
}

export const useClientRole = (props) => {
    const [loading, setLoading] = useState(true)

    const [role, setRole] = useState(props.role)

    const [users, setUsers] = useState([])

    const authorization = getAuthorizationFromLocal()
    const currentEmail = authorization
        ? authorization[0].email
        : false

    const loadClientUsersFromFirebase = async () => {
        const users = await getUsersFromFirebase()

        const clients = authorization[0].clients.split(',').map(e => e.trim()).filter(e => e !== '')

        const filteredClientUsers = users
            .filter(u => {
                if (u.client_admin) return true
                return false
            })
            .filter(u => {
                const clientArr = u.clients.split(',').map(e => e.trim()).filter(e => e !== '')
                let include = false
                for (let i = 0; i < clientArr.length; i++) {
                    if (clients.includes(clientArr[i])) include = true
                }
                if (include) return true
                return false
            })
        setUsers(filteredClientUsers)
    }

    useEffect(() => {

        setLoading(true)
        if (!authorization) {
            props.push({ pathname: `/` })
            return
        }
        getRole(currentEmail)
            .then((roleLvl) => {

                if (authorization && !roleLvl.client_admin) {
                    props.push({ pathname: `/report/summary` })
                    return
                }
                setRole(roleLvl)
                if (!users.length) {
                    loadClientUsersFromFirebase()
                        .then(() => {
                            setLoading(false)
                        })
                }
            }
            )

    }, [])

    return { role, users, loadClientUsersFromFirebase, loading }
}
