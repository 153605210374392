import React, { useEffect, useState } from "react";
import { Navbar, NavbarBrand, Collapse, NavbarToggler, Nav, NavItem, NavLink } from "reactstrap";
import { withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/images/logo.svg";

import { getAuthorizationFromLocal } from "../utils/utilities";


const Navigation = (props) => {

  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line
  const [remove, setRemove] = useState(false)

  const [role, setRole] = useState(false)

  const toggle = () => setIsOpen(prevState => !prevState);

  const handleRedirect = () => new Promise(resolve => resolve(props.history.push("/")))

  const path = window.location.pathname

  useEffect(() => {
    setRole(props.role)
  }, [props.role])

  const dashboardRedirect = () => {
    if (role.admin || role.super_admin || role.ycs_support) {
      props.history.push('/admin/dashboard')
      return
    } else if (role.client_admin) {
      props.history.push('/client/dashboard')
    }
  }

  const reportRedirect = () => {
    if (role.admin || role.super_admin || role.ycs_support) {
      props.history.push('/report/TransSummary')
      return
    } else if (role.client_admin) {
      props.history.push('/report/summary')
    }
  }

  return (
    <div>
      <Navbar color="light" light expand="sm">
        <NavbarBrand href="https://www.ycspr.net/">
          <Logo className="logo" />
        </NavbarBrand>
        {/* <NavbarBrand className='w-100 text-center d-flex justify-content-end'>
          {role.name ? <span className="text-medium mr-30px">Welcome, <strong className="blue-text bold text-big">{role.name}</strong></span> : ''}
        </NavbarBrand> */}
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto " navbar>
            {role.name
              ? <NavItem>
                <NavLink
                  className={"mr-30px p-1 mt-1 blue-text bold hv-op1"}>
                  {/* Welcome, <strong className="blue-text bold text-big">{role.name}</strong> */}
                  <span className="text-dark">Welcome, <strong className="blue-text bold">{role.name}</strong></span>
                </NavLink>
              </NavItem>
              : <></>}
            {role && !role.view_only && (
              <NavItem>
                <NavLink
                  className={`${path.includes('dashboard') ? 'text-dark' : ''} p-1 mt-1`}
                  onClick={dashboardRedirect}>
                  Dashboard
                </NavLink>
              </NavItem>)}
            {getAuthorizationFromLocal() !== null && (
              <>
                {localStorage.length !== 0 && (
                  <>
                    <NavItem>
                      <NavLink
                        className={`${path.includes('mmary') ? 'text-dark' : ''} p-1 mt-1`}
                        onClick={reportRedirect}>
                        Summary
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </>
            )}
            <NavItem>
              <NavLink
                className="p-1 mt-1"
                href="https://ycspr1.atlassian.net/servicedesk/customer/portal/4">
                Support
              </NavLink>
            </NavItem>
            {getAuthorizationFromLocal() !== null && (
              <>
                {localStorage.length !== 0 && (
                  <>
                    <NavItem>
                      <NavLink
                        className="p-1 text-danger mt-1"
                        onClick={() => props.onLogOut(() =>
                          handleRedirect()
                            .then(() => {
                              setRemove(prevState => !prevState)
                              setRole(false)
                            }) //Forcing a re-render on the component
                        )}>
                        Logout
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </>
            )}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}


export default withRouter(Navigation);
