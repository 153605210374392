import axios from "axios";
import { ref, child, get } from 'firebase/database'

import { database } from "../firebase/firebaseConfig";


export const getIvuTrans = async (endpoint) => {
  try {
    if (endpoint === "summary") {
      var ids = JSON.parse(localStorage.authorization);
      if (ids[0].clients === '') {
        const request = await axios.get(`https://ycsapi.azurewebsites.net/api/boss/TransSummary`);
        return await request.data;
      }
      const request = await axios.get(`https://ycsapi.azurewebsites.net/api/boss/${endpoint}?clients=${ids[0].clients}`);
      return await request.data;
    }
    else {
      const request = await axios.get(`https://ycsapi.azurewebsites.net/api/boss/${endpoint}`);
      return await request.data;
    }

  } catch (error) {
    return error;
  }
};

export const getAuthorizationFromLocal = () => {
  return JSON.parse(localStorage.getItem("authorization"));
};

export const getPOSdata = async endpoint => {
  try {
    // var ids = JSON.parse(localStorage.authorization);
    const request = await axios.get(`https://ycsapi.azurewebsites.net/api/boss/`);
    return await request.data;
  } catch (error) {
    return error;
  }
}

export const getUsersFromFirebase = async () => {
  const dbRef = ref(database);

  const users = await get(child(dbRef, '/users'))
    .then((snapshot) => { //get users from firestore db

      if (snapshot.exists()) {
        const users = Object.values(snapshot.val()) //Object to Array, firebase realtime database doesn't store arrays
        let i = 0
        snapshot.forEach((childSnapshot,) => {
          const childKey = childSnapshot.key;
          users[i].key = childKey
          i++
        })
        return users

      } else {
        console.log("No data available");
        return false
      }
    }).catch((error) => {
      console.error(error.message);
      return false

    });

  return users
}

export const getAdmin = async (email) => {

  if (!email) return false

  const users = await getUsersFromFirebase()

  return users.find(user => user.email === email).admin

}

export const getRole = async (email) => {
  if (!email) return false

  const users = await getUsersFromFirebase()
  const currentUser = users.find(user => user.email === email)

  if (!currentUser) return false

  if (currentUser.super_admin) return {
    super_admin: true,
    name: currentUser.first_name && currentUser.last_name ? currentUser.first_name + ' ' + currentUser.last_name : undefined
  }
  if (currentUser.admin) return {
    admin: true,
    clients: currentUser.clients,
    name: currentUser.first_name && currentUser.last_name ? currentUser.first_name + ' ' + currentUser.last_name : undefined
  }
  if (currentUser.view_only) return {
    view_only: true,
    clients: currentUser.clients,
    name: currentUser.first_name && currentUser.last_name ? currentUser.first_name + ' ' + currentUser.last_name : undefined
  }

  return false

}

export const transformClients = (clients) => {
  return clients.split(',').map(e => e.trim()).filter(e => e !== '')
}

export const clientsCorrectForm = (clients) => {
  const numbersRegEx = /^\d+$/;
  return Array.from(new Set(clients.split(',').map(e => e.trim()).filter(e => numbersRegEx.test(e)).sort((a, b) => a - b))).join(',')
}

export const matchClients = (adminClients, userClients) => {
  const adminClientsNumber = transformClients(adminClients)
  if (!userClients) return false
  const employeesClientsNumber = transformClients(userClients)

  let isEmployee = false

  employeesClientsNumber
    .forEach((u) => {
      if (adminClientsNumber.includes(u)) isEmployee = true
    })

  return isEmployee

}

export const matchUsersWithClientIds = (users) => {

  const adminClientsIds = getAuthorizationFromLocal()[0].clients
  const filter_users = []

  users.forEach((user) => {
    if (!user.clients || user.clients === '') return
    let match = true

    user.clients.split(',').forEach(id => {
      if (!match) return
      if (!adminClientsIds.split(',').includes(id)) match = false
    })

    if (match) filter_users.push(user)
  })

  return filter_users
}

export const namesWithClientIds = (users) => {

  // const adminClientsIds = getAuthorizationFromLocal()[0].clients
  const filter_users = []

  users.forEach((user) => {
    let match = true

    if (filter_users.find(u => user.CLIENT_ID === u.CLIENT_ID)) match = false

    if (match) filter_users.push(user)
  })

  return filter_users
}