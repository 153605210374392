import React from "react";
import Logo from "../assets/images/404.jpg";
import { Link } from "react-router-dom";

const Error = ({ onLogOut, onAuthenticated }) => (
  <div className="text-center">
    <img src={Logo} alt="404 Error" className="img-fluid" />
    <Link
      to="/"
      className="btn btn-primary mt-4 mb-3"
      onClick={() => onAuthenticated && onLogOut()}>
      Back to login
    </Link>
  </div>
);

export default Error;
