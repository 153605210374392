import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";
import escapeRegExp from "escape-string-regexp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faStoreAlt, faUpload, faUser, faIndustry, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";

import { getIvuTrans, getAuthorizationFromLocal, getRole, namesWithClientIds } from "../utils/utilities";

import Search from "./Search";
import Header from "./Header";
import Loader from "./Loader";
import Error from "./Error";
import Footer from "./Footer";
import InfoModal from "./InfoModal";

class TransSummaryTable extends Component {
  state = {
    trans: [],
    query: "",
    error: false,
    loading: false,
    authenticated: this.props.onAuthenticated,
    role: false,
    clientStores: [],
    infoOpen: false,
    infoId: []

  };

  componentDidMount = async () => {

    const authorization = getAuthorizationFromLocal()
    if (!authorization) {
      this.props.history.push("/");
      return
    }

    this.setState({ loading: true });

    const { endpoint } = this.props.match.params;

    const currentEmail = authorization[0].email
    const clients = authorization[0].clients

    getRole(currentEmail)//get auth lvl and role(admin,admin with filters,view-only, superadmin)
      .then((role) => {
        const auth = role && (role.admin || role.super_admin || role.view_only) //If true user have auth to be in transactions page
          ? true
          : false

        this.setState(
          {
            role: role
          },
          async () => {

            if (auth && endpoint !== 'TransSummary' && (clients === '' || !role.clients || role.clients === '')) {
              this.props.history.push("/report/TransSummary");
            }
            if (auth && endpoint !== 'summary' && (clients !== '' || role.clients !== '')) {
              this.props.history.push("/report/summary")
            }

            const request = await getIvuTrans(clients === '' || !role.clients || role.clients === '' ? 'TransSummary' : 'summary');

            if (!role.clients || role.clients === '') {//if user belongs to YCS it will load clients id and clients name to an info modal
              const info = namesWithClientIds(request)
              this.setState({ infoId: [...info] })
            }
            if (request.response && request.response.status !== 200) {
              this.setState({ error: true });
            } else {
              this.setState({ trans: request }, () =>
                setTimeout(() => this.setState({ loading: false }), 1200)
              );
            }
          })
      })
  };

  checkDate = days => {
    const day = new Date();
    return {
      today: moment(new Date()).format("L"),
      twoWeeksBack: new Date(day.setDate(day.getDate() - days)),
    };
  };

  returnQueryResult = query => {
    let searchResult = [];
    const splittedQuery = query.split('*')

    const clientName = splittedQuery[0]
    const clientId = splittedQuery[1]


    if (query) {
      const match = new RegExp(escapeRegExp(clientName), "i");
      searchResult = this.state.trans.filter(trans => match.test(trans.NAME));
      if (searchResult.length === 0) {
        searchResult = this.state.trans.filter(trans => match.test(trans.POS_ID));
      }
      if (clientId && searchResult.length > 0) {
        searchResult = searchResult.filter(client => client.CLIENT_ID.toString().startsWith(clientId))
      }
    }
    else {
      searchResult = this.state.trans;
    }
    return searchResult.sort((a, b) => a.POS_ID - b.POS_ID);
  };

  updateSearch = query => this.setState({ query: query.trim() });

  render() {
    const { trans, query, loading, error } = this.state;
    const date = this.checkDate(14);

    return loading ? (
      <Loader />
    ) : error ? (
      <Error />
    ) : (
      <>
        <Header />
        <Search onUpdateSearch={this.updateSearch} placeholder='Search by Store name' />
        <Table bordered hover className="mt-5 mb-4">
          <thead className="thead-dark text-center ">
            <tr>
              {(this.state.role && !this.state.role.view_only && (!this.state.role.clients || this.state.role.clients === '')) && (
                <th scope="row">Client Id  <span className="pointer animate-opacity" onClick={() => { this.setState({ infoOpen: !this.state.infoOpen }) }}><FontAwesomeIcon size="1x" icon={faInfoCircle} /></span></th>
              )}
              <th scope="row">Pos Id</th>
              {this.state.role && (!this.state.role.clients || this.state.role.clients === '') && (
                <th scope="row">Client Name</th>
              )}
              <th scope="row">Store Name</th>
              <th scope="row">Upload</th>
              <th scope="row">Reference</th>
            </tr>
          </thead>
          <thead className="thead-light text-center">
            <tr>
              {(this.state.role && !this.state.role.view_only && (!this.state.role.clients || this.state.role.clients === '')) && (
                <th scope="row" className="min-w-80px">
                  <FontAwesomeIcon size="1x" icon={faUser} />
                </th>)
              }
              <th scope="row">
                <FontAwesomeIcon size="1x" icon={faDesktop} />
              </th>
              {this.state.role && (!this.state.role.clients || this.state.role.clients === '') && (
                <th scope="row">
                  <FontAwesomeIcon size="1x" icon={faIndustry} />
                </th>
              )}
              <th scope="row">
                <FontAwesomeIcon size="1x" icon={faStoreAlt} />
              </th>
              <th scope="row">
                <FontAwesomeIcon size="1x" icon={faUpload} />
              </th>
            </tr>
          </thead>
          <tbody>
            {trans.length !== 0 &&
              this.returnQueryResult(query)
                .filter(pos => pos.TEST !== "Y")
                .map((pos, index) => (
                  <tr key={index}>
                    {(this.state.role && !this.state.role.view_only&& (!this.state.role.clients || this.state.role.clients === '')) && <td className="text-center">{pos.CLIENT_ID}</td>}
                    <td className="text-center">{pos.POS_ID}</td>
                    {this.state.role && (!this.state.role.clients || this.state.role.clients === '') && (
                      <td className="text-center">{pos.Client_Company}</td>
                    )}
                    <td className="text-center">
                      {this.state.role && (this.state.role.client_admin || this.state.role.view_only) ? pos.NAME.replace("El Meson -", "") : pos.NAME}
                    </td>
                    <td
                      className={
                        pos.last_inserted_date === null
                          ? "table-warningX2 text-center"
                          : new Date(pos.last_inserted_date) < new Date(date.twoWeeksBack)
                            ? "table-dangerX2 text-center"
                            : "table-success text-center"
                      }>
                      {pos.last_inserted_date === null ? (
                        "Offline"
                      ) : (
                        <Moment format="MM/D/YY" withTitle className="text-center">
                          {pos.last_inserted_date}
                        </Moment>
                      )}
                    </td>
                    <td className="text-center">
                      {pos.Pos_Name}
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        <InfoModal isOpen={this.state.infoOpen} toggle={() => this.setState({ infoOpen: !this.state.infoOpen })}>
          <div className="max-h-500px overflow-y-scroll">
            <Table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>CLIENT</th>
                </tr>
              </thead>
              <tbody>
                {this.state.infoId.length
                  ? this.state.infoId.map(e => <tr key={e.CLIENT_ID}>
                    <td>{e.CLIENT_ID}</td>
                    <td>{e.Client_Company}</td>
                  </tr>)
                  : <></>
                }

              </tbody>
            </Table>
            <hr></hr>
          </div>
        </InfoModal>
        {!loading && <Footer />}
      </>
    );
  }
}

export default TransSummaryTable;
