import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { getAdmin, getAuthorizationFromLocal, getRole, getUsersFromFirebase } from "./utils/utilities";

import Login from "./components/Login";
import Error from "./components/Error";
import Navigation from "./components/Navigation";
import TransSummaryTable from "./components/Transactions";
import Dashboard from "./components/Dashboard";

import "./App.css";

class App extends Component {
  state = {
    email: "",
    password: "",
    authenticated: false,
    emailError: false,
    passwordError: false,
    credentialsError: false,
    admin: false,
    role: false,
    storeSet: []
  };

  setAdminTrue = () => {
    this.setState({ admin: true })
  }

  setStores = (clientStores) => {
    this.setState({ storeSet: clientStores })
  }

  componentDidMount = () => {

    this.checkLocalStorageForAuth()

    const token = getAuthorizationFromLocal()

    if (token) {
      const currentEmail = token[0].email
      getAdmin(currentEmail)
        .then(res => {
          this.setState({ admin: res })
        })
    }
    if (token) {
      const currentEmail = token[0].email
      getRole(currentEmail)
        .then((isAdmin) => {
          this.setState({ role: isAdmin })
        })
    }

  };

  checkLocalStorageForAuth = () => {
    if (!JSON.parse(localStorage.getItem("authorization"))) {
      return <Redirect to="/" />;
    } else {
      const auth = JSON.parse(localStorage.getItem("authorization"));
      return <Redirect to={auth["0"]} />;
    }
  };

  handleInputs = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  logOut = cb => {
    this.setState({ authenticated: false, email: "", password: "", admin: false }, () =>
      localStorage.clear()
    );
    cb();
  };

  validateFields = async (email, password) => {
    const users = await getUsersFromFirebase()

    const user = users
      ? users.find(user => user.email === email)
      : false

    return user && user.password === password
      ? {
        ...user,
        auth: true
      }
      : { auth: false }
  };

  clearInputs = () => {
    const login = document.forms[0];
    localStorage.clear();
    this.setState({ email: "", password: "" }, () => login.reset());
  };

  login = async (cb) => {
    const { email, password } = this.state;
    const valid = await this.validateFields(email, password);

    if (valid.auth && email && password) {
      this.setState({
        authenticated: true,
        admin: valid.admin || valid.ycs_support || valid.super_admin || valid.client_admin ? true : false
      });
      getRole(email)
        .then((isAdmin) => {
          this.setState({ role: isAdmin })

        })
      var result = { endpoint: valid.endpoint, clients: valid.clients, email: valid.email, admin: valid.admin, ycs_support: valid.ycs_support }

      cb(result); // Callback will send the endpoint,authenticated && admin to the login
    }

    if (!valid.auth && email && password) {
      this.setState({ credentialsError: true }, () =>
        setTimeout(() => this.setState({ credentialsError: false }, () => this.clearInputs()), 1350)
      );
    }

    if (password.length <= 3) {
      this.setState({ passwordError: true }, () =>
        setTimeout(() => this.setState({ passwordError: false }), 1350)
      );
    }

    if (email.length === 0) {
      this.setState({ emailError: true }, () =>
        setTimeout(() => this.setState({ emailError: false }), 1350)
      );
    }
  };

  setGlobalAdmin = () => {
    this.setState({ admin: true })
  }

  render() {
    const { passwordError, emailError, authenticated, credentialsError, admin } = this.state;

    return (
      <Router>
        <>
          <Navigation onAuthenticated={authenticated} onLogOut={this.logOut} role={this.state.role} />
          <div className="container mt-5">
            <Switch>
              <Route
                path="/"
                exact
                render={({ history }) => (
                  <>
                    <Login
                      {...history}
                      onHandleInputs={this.handleInputs}
                      onCredentialsError={credentialsError}
                      onPasswordError={passwordError}
                      onEmailError={emailError}
                      onLogin={this.login}
                    />
                  </>
                )}
              />
              <Route
                exact
                path="/report/:endpoint"
                render={({ history, match }) => (
                  <TransSummaryTable
                    history={history}
                    match={match}
                    admin={admin}
                    onAuthenticated={authenticated}
                    setStores={this.setStores}
                  />
                )}
              />
              <Route
                exact
                path={"/admin/dashboard"}
                render={({ history }) => (
                  <Dashboard
                    {...history}
                    admin={admin}
                    role={this.state.role}
                  />
                )}
              />
              <Route
                path="/404"
                render={() => <Error onAuthenticated={authenticated} onLogOut={this.logOut} />}
              />
              <Route
                render={() => <Error onAuthenticated={authenticated} onLogOut={this.logOut} />}
              />
            </Switch>
          </div>
        </>
      </Router>
    );
  }
}

export default App;
