import React, { useState } from 'react'
import { Button, Collapse } from 'reactstrap'
import { update, ref } from 'firebase/database'
import Swal from 'sweetalert2'

import { useAdminRole } from '../customHooks/useRole'
import { database } from '../firebase/firebaseConfig'

import Footer from './Footer'
import Header from './Header'
import Loader from './Loader'
import CreateUserForm from './CreateUserForm'
import DeleteUsersList from './DeleteUsersList'
import UsersTable from './UsersTable'
import Search from './Search'


function Dashboard(props) {

    const { role, users, loadUsersFromFirebase, loading } = useAdminRole(props)

    const [creatingUser, setCreatingUser] = useState(false)
    //eslint-disable-next-line
    const [deleting, setDeleting] = useState(false)
    const [usersToDelete, setUsersToDelete] = useState([])
    //eslint-disable-next-line
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => setIsOpen(prevState => !prevState)
    const [query, setQuery] = useState(null)

    const setDeletedUsers = (user) => {
        const isUserInDeletedList = usersToDelete.find(u => u.key === user.key)

        if (!isUserInDeletedList) {
            setUsersToDelete(prevState => [user, ...prevState])
        }
    }


    const updateUserData = async (id, property, data) => {

        await Swal.fire({
            title: 'Are you sure?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    update(ref(database, `/users/${id}`), {//firebase update
                        [property]: data
                    })
                        .then(async () => { //if succesfull goes in here without a response
                            Swal.fire('Changes updated Succesfully!', '', 'success')
                            await loadUsersFromFirebase()
                        })
                        .catch((e) => { //if an error occurred goes here with an error object
                            Swal.fire('An error ocurred while updating, try again', '', 'error')
                        })

                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
    }

    const silentUpdateUserData = async (id, property, data) => {
        await update(ref(database, `/users/${id}`), {//firebase update
            [property]: data
        })
        await loadUsersFromFirebase()

    }

    const deleteUsers = async () => {
        const selected = {}

        usersToDelete.forEach(u => {
            selected['users/' + u.key] = null  //setting the update value to null deletes it from firebase db
        })

        Swal.fire({
            title: 'Are you sure?',
            showDenyButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Cancel`,
        })
            .then((result) => {
                if (result.isConfirmed) {
                    update(ref(database), selected)
                        .then(async () => { //if succesfull goes in here without a response
                            Swal.fire('Users deleted Succesfully!', '', 'success')
                                .then(async () => {
                                    await loadUsersFromFirebase()
                                    setUsersToDelete([])
                                })
                        })
                        .catch((e) => { //if an error occurred goes here with an error object
                            Swal.fire('An error ocurred while deleting, try again', '', 'error')
                        })

                } else if (result.isDenied) {
                    Swal.fire('Users were not deleted', '', 'Success')
                }
            })

    }

    const searchResult = () => {

        if (query === '' || !query) return users

        const searchResult = [...users].filter(u => u.email.includes(query))

        return searchResult
    }

    return loading
        ? <Loader />
        : (
            <div>
                <Header />
                <br></br>
                <Search placeholder={'Search by email'} onUpdateSearch={(e) => setQuery(e)} />
                {role && (role.admin || role.super_admin)
                    ? <div className='w-100 mb-1 d-flex justify-content-end align-items-center gap-15'>
                        <div className='w-25'>
                            <Button
                                onClick={() => setCreatingUser(prevState => !prevState)}
                                color='primary'
                                block>
                                Upload User
                            </Button>
                        </div>
                        {/* <div>
                            <Button
                                onClick={() => setDeleting(prevState => !prevState)}
                                color='danger'
                                block
                            >
                                {deleting && usersToDelete.length > 0
                                    ? 'hide'
                                    : 'Delete Users'}
                            </Button>
                        </div> */}
                    </div>
                    : <></>}
                <Collapse isOpen={usersToDelete.length > 0}>
                    {usersToDelete.length === 0 && (
                        <p className='border border-danger text-center text-danger p-2'>Select the users you want to delete</p>
                    )}
                    {usersToDelete.length > 0 && (
                        <DeleteUsersList
                            usersToDelete={usersToDelete}
                            setUsersToDelete={setUsersToDelete}
                            deleteUsers={deleteUsers}
                        />
                    )
                    }
                </Collapse>
                <CreateUserForm
                    upload={() => loadUsersFromFirebase()}
                    creatingUser={creatingUser}
                    setCreatingUser={setCreatingUser}
                    users={users}
                    role={role}
                />
                <UsersTable
                    deleting={deleting}
                    users={searchResult()}
                    setDeletedUsers={setDeletedUsers}
                    usersToDelete={usersToDelete}
                    silentUpdate={silentUpdateUserData}
                    updateData={updateUserData}
                    role={role}
                    toggle={toggle}
                />
                <Footer />
            </div>
        )

}

export default Dashboard