// eslint-disable-next-line
const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
export const clientsRegEx = /^[-,0-9 ]+$/

export const validation = (values) => {

    const errors = {}
    if (values.email !== '' && !emailRegEx.test(values.email)) errors.email = 'It should be an email'
    if (values.clients !== '' && !clientsRegEx.test(values.clients)) errors.clients = 'Only numbers and commas'

    if (values.email === '') errors.email = undefined
    if (values.clients === '') errors.clients = undefined

    if (values.email !== '' && emailRegEx.test(values.email)) errors.email = undefined
    if (values.clients !== '' && clientsRegEx.test(values.clients)) errors.clients = undefined

    return errors
}

export const isEmail = (email) => {
    return emailRegEx.test(email)
        ? true
        : false
}

export const clientValidation = (clients) => {
    return clientsRegEx.test(clients)
        ? true
        : false
}