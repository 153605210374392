// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC1n_ja27SIaw_Z12pIeMjqyV7mer3DPSk",
    authDomain: "yibzer-d1e11.firebaseapp.com",
    databaseURL: "https://ycs-fiscal-reports.firebaseio.com",
    projectId: "yibzer-d1e11",
    storageBucket: "yibzer-d1e11.appspot.com",
    messagingSenderId: "236888322981",
    appId: "1:236888322981:web:26b0a147891f0122c5ae4c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app)

//App info
// export const analytics = getAnalytics(app);