import React from 'react'
import { Button, ListGroup, ListGroupItem } from 'reactstrap'


const DeleteUsersList = ({ usersToDelete, setUsersToDelete, deleteUsers }) => {
    return (
        <div className='border border-danger p-4'>
            <ListGroup >
                {usersToDelete.map((user, index) => <ListGroupItem key={index} color='danger'>
                    <div className='d-flex justify-content-between align-items-center font-weight-bold'>
                        {user.email}
                        <Button
                            onClick={() => setUsersToDelete(prevState => prevState.filter(u => u.email !== user.email))}
                            >
                            Undo
                        </Button>
                    </div>
                </ListGroupItem>)}
            </ListGroup>
            <Button onClick={deleteUsers} color='danger' block className='m-auto'>Delete Selected Users</Button>
        </div>
    )
}

export default DeleteUsersList
