import React from 'react';
import Spinner from '../assets/images/Spinner.svg';

const Loader = () => (
  <img
    src={Spinner}
    alt="Loading..."
    style={{
      display: 'block',
      width: 175,
      margin: '100px auto',
      height: 'auto',
    }}
  />
);
export default Loader;
