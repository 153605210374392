import React from 'react'
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from 'reactstrap'


const InfoModal = ({ isOpen, toggle , children}) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalHeader
                toggle={toggle}
            >Clients
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {/* <Button color='success' onClick={success}>
                    Save
                </Button> */}
                <Button color='danger' onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default InfoModal