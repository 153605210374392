import React, { useEffect, useState } from 'react'
import { Table } from 'reactstrap'
import { getIvuTrans } from '../utils/utilities'

import CustomRow from './CustomTableRow'


const UsersTable = ({ deleting, users, setDeletedUsers, updateData, role, usersToDelete, silentUpdate, toggle }) => {

    const [usersInTable, setUsersInTable] = useState(users)
    const [adminFilter, setAdminFilter] = useState(false)
    const [clientCompanies, setClientCompanies] = useState([])

    const getClientCompaniesName = async () => {
        if (role.clients && role.clients !== '') {
            let storeSet = []
            const trans = await getIvuTrans('summary')

            trans.forEach(e => {
                let isStoreInList = false
                for (let i = 0; i < storeSet.length; i++) {
                    if (storeSet[i] && storeSet[i].name === e.Client_Company) {
                        isStoreInList = true
                    }
                }
                if (!isStoreInList) storeSet.push({
                    name: e.Client_Company,
                    clientId: e.CLIENT_ID
                })
            })
            setClientCompanies(storeSet)
        }
    }

    useEffect(()=>{
        getClientCompaniesName()
    },[])

    useEffect(() => {
        setUsersInTable(users)
    }, [users])

    const filterByAdmin = () => {
        if (adminFilter) {
            setUsersInTable(users)
            setAdminFilter(false)
            return
        }

        const adminUsers = [...users].filter(u => u.admin === true)
        setAdminFilter(true)
        setUsersInTable(adminUsers)
    }

    return (
        <Table bordered hover className="mt-1 mb-4">
            <thead className="thead-dark text-center ">
                <tr id='AdminTableHeader'>
                    <th scope="row">Email</th>
                    <th scope='row'>Name</th>
                    <th scope="row">Password</th>
                    <th scope="row"
                        className={`pointer ${adminFilter ? 'bg-secondary' : ''}`}
                        onClick={filterByAdmin}
                    >
                        Admin
                    </th>
                    {role.super_admin && <th scope="row">Super Admin</th>}
                    {!role.client_admin && <th scope="row">{(role.clients && role.clients !== '')?'Entities':'Clients'}</th>}
                    <th scope='row'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {users.length !== 0
                    ? role.super_admin
                        ? usersInTable
                            .map((user, index) => (
                                <CustomRow
                                    key={index}
                                    usersToDelete={usersToDelete}
                                    role={role}
                                    setDelete={setDeletedUsers}
                                    deleting={deleting}
                                    fbId={user.key} //firebase user ID
                                    update={updateData}
                                    email={user.email}
                                    silentUpdate={silentUpdate}
                                    password={user.password}
                                    ycs_support={user.ycs_support}
                                    admin={user.admin}
                                    endpoint={user.endpoint}
                                    clients={user.clients}
                                    super_admin={user.super_admin}
                                    toggle={toggle}
                                    view_only={user.view_only}
                                    first_name={user.first_name}
                                    last_name={user.last_name}
                                />
                            ))
                        : usersInTable
                            .filter(u => u.super_admin !== true)
                            .map((user, index) => (
                                <CustomRow
                                    usersToDelete={usersToDelete}
                                    key={index}
                                    role={role}
                                    setDelete={setDeletedUsers}
                                    silentUpdate={silentUpdate}
                                    ycs_support={user.ycs_support}
                                    deleting={deleting}
                                    fbId={user.key} //firebase user ID
                                    update={updateData}
                                    email={user.email}
                                    password={user.password}
                                    admin={role.admin || role.super_admin || role.ycs_support ? user.admin : user.client_admin}
                                    endpoint={user.endpoint}
                                    clients={user.clients}
                                    toggle={toggle}
                                    view_only={user.view_only}
                                    clientCompanies={clientCompanies}
                                    setClientCompanies={setClientCompanies}
                                    first_name={user.first_name}
                                    last_name={user.last_name}
                                />
                            ))
                    : []
                }
            </tbody>
        </Table>
    )
}

export default UsersTable