import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Search = ({ onUpdateSearch,placeholder }) => (
  <div className="input-group mb-3">
    <div className="input-group-prepend">
      <span className="input-group-text">
        <FontAwesomeIcon icon={faSearch} />
      </span>
    </div>
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      autoFocus
      aria-label="Search"
      name="query"
      onChange={e => onUpdateSearch(e.target.value)}
    />
  </div>
);

export default Search;
