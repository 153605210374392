import React, { useEffect } from "react";
import { Button, Form, FormGroup, Input, InputGroupAddon, InputGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Footer from "./Footer";

import uuidv1 from "uuid/v1";
import { getAuthorizationFromLocal } from "../utils/utilities";

const setAuthorizationOnLocal = (...arg) => {
  localStorage.clear(); // Cleanup before setting the auth token
  localStorage.setItem("authorization", JSON.stringify({ token: uuidv1(), ...arg }));
};

export default function Login({
  onHandleInputs,
  onLogin,
  onPasswordError,
  onEmailError,
  onCredentialsError,
  push,
}) {

  useEffect(() => {
    const authorization = getAuthorizationFromLocal()
    if (authorization) {
      push({ pathname: `/report/summary` })
    }
  }, [])

  return (
    <>
      <Header />
      <div className="row justify-content-md-center">
        <div className="col-lg-6 mb-5">
          <Form
            className="mt-5"
            onSubmit={e => {
              e.preventDefault();
              onLogin(result => {
                const { endpoint, email, clients } = result
                setAuthorizationOnLocal({ email, endpoint, clients });
                push({ pathname: `/report/${result.admin || result.ycs_support ? 'TransSummary' : 'summary'}` });
              });
            }}>
            <FormGroup>
              <InputGroup>
                {onEmailError && (
                  <InputGroupAddon addonType="prepend">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-danger center" />
                  </InputGroupAddon>
                )}
                <Input
                  autoFocus
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  onChange={onHandleInputs}
                />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <InputGroup>
                {onPasswordError && (
                  <InputGroupAddon addonType="prepend">
                    <FontAwesomeIcon icon={faExclamationCircle} className="text-danger center" />
                  </InputGroupAddon>
                )}
                <Input
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  onChange={onHandleInputs}
                />
              </InputGroup>
              {onCredentialsError && <p className=" text-danger">Credentials are not valid</p>}
            </FormGroup>
            <Button>Log in</Button>
          </Form>
        </div>
      </div>
      <Footer />
    </>
  );
}
