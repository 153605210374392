import React from "react";

const Footer = () => {
  
  const year = new Date().getFullYear()

  return (
  <footer className={window.location.pathname !== "/" ? "relative-footer" : "fixed-footer"}>
    <p className="text-center">© {year} YCSPR,CORP</p>
    <div className="flex-container">
      <div className="left">
        <p>
          YCS PR is a software development company based in Puerto Rico that specializes in a wide
          range of solutions for the restaurant industry.
          <br />
          We work together with entrepreneurs and businesses creating custom softwares, mobile apps,
          and integrations to meet their needs.
        </p>
      </div>
      <div className="right">
        <p>
          RR6 Box 9337,
          <br /> San Juan, PR 00926
          <br />
          <a href="mailto: contact@ycspr.net"> contact@ycspr.net</a>
          <br />
          <a href="tel:787-720-1380">787-720-1380</a>
        </p>
      </div>
    </div>
  </footer>
)};

export default Footer;
