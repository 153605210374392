import React, { useEffect, useState } from 'react'
import { Button, Form, FormGroup, Input, InputGroupAddon, InputGroup, Modal, ModalBody, ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { ref, set } from 'firebase/database'
import uuidv1 from 'uuid/v1'
import * as passwordGen from 'generate-password'

import { database } from '../firebase/firebaseConfig'
import { clientValidation } from '../utils/validations';
import { clientsCorrectForm, getIvuTrans } from '../utils/utilities';


const initialValues = {
    first_name:'',
    last_name:'',
    email: '',
    password: '',
    clients: '',
    admin: false
}

const CreateUserForm = ({ upload, creatingUser, setCreatingUser, users, role }) => {

    const [values, setValues] = useState(initialValues)
    const [userType, setUserType] = useState('')
    const [typeDropdown, setTypeDropdown] = useState(false)
    const [passErr, setPassErr] = useState('')
    const [clientErr, setClientErr] = useState('')
    const [firstNameErr, setFirstNameErr] = useState('')
    const [lastNameErr, setLastNameErr] = useState('')
    const [userTypeErr, setUserTypeErr] = useState('')
    const [clientCompanies, setClientCompanies] = useState([])



    const getClientCompaniesName = async () => {
        if (role.admin && (role.clients && role.clients !== '')) {
            let storeSet = []

            const trans = await getIvuTrans('summary')

            trans.forEach(e => {
                let isStoreInList = false
                for (let i = 0; i < storeSet.length; i++) {
                    if (storeSet[i] && storeSet[i].name === e.Client_Company) {
                        isStoreInList = true
                    }
                }
                if (!isStoreInList) storeSet.push({ name: e.Client_Company, clientId: e.CLIENT_ID, selected: false })
            })
            setClientCompanies(storeSet)
        }
    }

    useEffect(() => {
        getClientCompaniesName()
    }, [])

    const setFormType = (type) => {
        switch (type) {
            case 'superadmin':
                setUserType(type)
                setValues({
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    admin: true,
                    super_admin: true,
                    clients: ''
                })
                break
            case 'admin':
                setUserType(type)
                setValues({
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    admin: true,
                    clients: ''
                })
                break
            case 'employee':
                setUserType(type)
                setValues({
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    clients: '',
                    view_only: true
                })
                break
            default:
                setUserType('')
                setValues(initialValues)
                break
        }
        setUserTypeErr('')
    }

    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }

    const createUser = (data) => {
        const user = { ...data }

        const userExists = users.find(u => u.email === user.email)

        if (userExists) {
            Swal.fire(`Email: ${user.email} belongs to an existing user.`, '', 'error')
            return
        }

        const uuid = uuidv1()
        const newUserDbRef = ref(database, 'users/' + uuid)

        set(newUserDbRef, user)
            .then(() => {
                Swal.fire('user uploaded successfully', '', 'success')
                    .then(async () => {
                        await upload()
                        setValues({ ...initialValues })
                    })
            })
            .catch((e) => {
                Swal.fire('Error occurred, try again', '', 'error')
            })
    }

    const validateAndTransformData = () => { //Transform as give the data the correct structure
        let validForm = true
        if (values.first_name === '') {
            setFirstNameErr('Required')
            validForm = false
        } else if (!/^[a-zA-Z\s]*$/.test(values.first_name)) {
            setFirstNameErr('Only letters')
            validForm = false
        }
        if (values.last_name === '') {
            setLastNameErr('Required')
            validForm = false
        } else if (!/^[a-zA-Z\s]*$/.test(values.last_name)) {
            setLastNameErr('Only letters')
            validForm = false
        }
        if (userType === '') {
            setUserTypeErr('Required')
            validForm = false
        } else {
            setUserTypeErr('')
        }

        if (values.password === '') {
            setPassErr('Password is required')
            validForm = false
        } else {
            setPassErr('')
        }
        if ((role.admin) && (!role.clients || (role.clients && role.clients === ''))) {
            if (values.clients !== '' && !clientValidation(values.clients)) {
                setClientErr('Commas and numbers only')
                validForm = false
            } else {
                setClientErr('')
            }
        }
        let clientsIds
        if (role.admin && (role.clients && role.clients !== '')) {
            let clientsSelected = false
            let idArr = []
            clientCompanies.forEach((e) => {
                if (e.selected) {
                    idArr.push(e.clientId)
                    clientsSelected = true
                }
            })
            if (!clientsSelected) {
                setClientErr('Select at least one')
                validForm = false
            }
            clientsIds = idArr.join(',')
        }
        if (!validForm) return {
            isFormValid: false
        }

        return {
            isFormValid: true,
            clientsIds: clientsIds
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const { isFormValid, clientsIds } = validateAndTransformData()

        if (!isFormValid) return

        createUser((role.admin && (role.clients && role.clients !== ''))
            ? {
                ...values,
                first_name:values.first_name[0].toUpperCase() + values.first_name.substring(1),
                last_name:values.last_name[0].toUpperCase() + values.last_name.substring(1),
                clients: clientsIds
            }
            : {
                ...values,
                first_name:values.first_name[0].toUpperCase() + values.first_name.substring(1),
                last_name:values.last_name[0].toUpperCase() + values.last_name.substring(1),
                clients: clientsCorrectForm(values.clients)
            })
        setPassErr('')
        setClientErr('')
        setFirstNameErr('')
        setLastNameErr('')
        if (role.admin && (role.clients && role.clients !== '')) {
            const clearCompaniesSelected = [...clientCompanies]
            clientCompanies.forEach((e, i) => {
                clearCompaniesSelected[i].selected = false
            })
            setClientCompanies(clearCompaniesSelected)

            for (let i = 0; i < clientCompanies.length; i++) {
                document.getElementById(clientCompanies[i].clientId).checked = false
            }
        }
    }

    const handleToggle = (e) => {

        const id = e.target.value

        const ix = clientCompanies.findIndex(e => e.clientId.toString() === id)
        if (ix === -1) return
        let companies = [...clientCompanies]
        companies[ix].selected = !companies[ix].selected
        setClientCompanies(companies)
    }

    return (
        <Modal
            isOpen={creatingUser}
            toggle={() => {
                setCreatingUser(prevState => !prevState);
                setValues(initialValues); setUserType('');
                setUserTypeErr('');
                setPassErr('');
                setClientErr('');
                setUserType('');
                setLastNameErr('');
                setFirstNameErr('')
            }}
        >
            <ModalHeader
                toggle={() => {
                    setCreatingUser(prevState => !prevState);
                    setValues(initialValues);
                    const clearCompaniesSelected = [...clientCompanies]
                    clientCompanies.forEach((e, i) => {
                        clearCompaniesSelected[i].selected = false
                    })
                    setClientCompanies(clearCompaniesSelected)
                }}
            >Upload Users
            </ModalHeader>
            <ModalBody>
                <div className='p-2'>
                    <Form
                        onSubmit={handleSubmit}
                    >
                        <FormGroup>
                            <div className='d-flex align-items-center'>

                                <Dropdown direction="right" isOpen={typeDropdown} toggle={() => setTypeDropdown((prevState) => !prevState)}>
                                    <DropdownToggle
                                        caret
                                        className={`${userType !== '' ? 'bg-success text-light border-success' : 'bg-warning border-warning text-dark'}`}>
                                        Select the user type
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {role.super_admin && <DropdownItem onClick={() => setFormType('superadmin')}>Superadmin</DropdownItem>}
                                        {(role.admin || role.super_admin) && <DropdownItem onClick={() => setFormType('admin')}>Admin</DropdownItem>}
                                        <DropdownItem onClick={() => setFormType('employee')}>view-only</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <span className='ml-3 font-weight-bold'>
                                    {userType !== ''
                                        ? userType === 'ycs_support'
                                            ? 'YCS Support'
                                            : userType === 'client_client'
                                                ? 'Admin'
                                                : userType === 'employee'
                                                    ? 'view-only'
                                                    : userType[0].toUpperCase() + userType.substring(1)
                                        : ''}
                                </span>
                            </div>
                            <p className='text-danger'>{userTypeErr}</p>
                        </FormGroup>
                        <hr></hr>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">First Name *</InputGroupAddon>
                                <Input
                                    type='text'
                                    name='first_name'
                                    onChange={handleChange}
                                    value={values.first_name}
                                    placeholder='Enter First Name'
                                />
                            </InputGroup>
                            {firstNameErr !== '' ? <p className='text-danger small_text'>{firstNameErr}</p> : ''}
                            <br></br>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">Last Name *</InputGroupAddon>
                                <Input
                                    type='text'
                                    name='last_name'
                                    onChange={handleChange}
                                    value={values.last_name}
                                    placeholder='Enter Last Name'
                                />
                            </InputGroup>
                            
                            {lastNameErr !== '' ? <p className='text-danger small_text'>{lastNameErr}</p> : ''}
                        </FormGroup>
                        <hr></hr>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">Email *</InputGroupAddon>
                                <Input
                                    type='email'
                                    required
                                    name='email'
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder='ycs@ycs.pr'
                                />
                            </InputGroup>
                            <br></br>
                            <InputGroup>
                                <Button
                                    color={`${values.password !== '' ? 'success' : 'warning'}`}
                                    block
                                    onClick={() => {
                                        const newPassword = passwordGen.generate({
                                            length: 20,
                                            numbers: true,
                                        })
                                        setValues(prevState => {
                                            return {
                                                ...prevState,
                                                password: newPassword
                                            }
                                        })
                                    }}
                                >{values.password !== ''
                                    ? <>
                                        Secure password generated &nbsp;<FontAwesomeIcon size='1x' icon={faCheck} />
                                    </>
                                    : 'Generate Secure Password *'}
                                </Button>
                            </InputGroup>
                            {
                                passErr !== ''
                                    ? <p className='text-danger small_text'>{passErr}</p>
                                    : ''
                            }
                        </FormGroup>
                        <hr></hr>
                        {(userType !== 'superadmin' && role.super_admin) || (role.admin && (!role.clients || role.clients === ''))
                            ? <>
                                <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">{userType === 'client' ? 'Clients*' : 'Clients'}</InputGroupAddon>
                                        <Input
                                            type='text'
                                            name='clients'
                                            onChange={handleChange}
                                            values={values.clients}
                                            placeholder='Numbers and commas only'
                                        />
                                    </InputGroup>
                                    <p className='small_text'>Leave empty for all clients</p>
                                    {
                                        clientErr !== ''
                                            ? <p className='text-danger small_text'>{clientErr}</p>
                                            : ''
                                    }
                                </FormGroup>
                                <hr></hr>
                            </>
                            : <></>}
                        {role.admin && (role.clients && role.clients !== '') && (

                            <div>
                                <Alert color='secondary'>
                                    <h4>Select {(role.clients && role.clients !== '') ? ' Entities' : ' Clients'}:</h4>
                                    <hr></hr>
                                    <div className='d-flex flex-column align-items-start max-h-150px overflow-y-scroll'>
                                        {clientCompanies.map((client, i) => {
                                            return (
                                                <label key={i}><input type={'checkbox'} id={client.clientId} onChange={handleToggle} value={client.clientId} />{client.name}</label>
                                            )
                                        })}
                                    </div>
                                </Alert>
                                {role.admin && <p className='text-danger'>{clientErr}</p>}
                                <hr></hr>
                            </div>)}
                        {/* <FormGroup>
                            <InputGroup>
                                <Button
                                    color={`${values.password !== '' ? 'success' : 'warning'}`}
                                    block
                                    onClick={() => {
                                        const newPassword = passwordGen.generate({
                                            length: 20,
                                            numbers: true,
                                        })
                                        setValues(prevState => {
                                            return {
                                                ...prevState,
                                                password: newPassword
                                            }
                                        })
                                    }}
                                >{values.password !== ''
                                    ? <>
                                        Secure password generated &nbsp;<FontAwesomeIcon size='1x' icon={faCheck} />
                                    </>
                                    : 'Generate Secure Password *'}
                                </Button>
                            </InputGroup>
                            {
                                passErr !== ''
                                    ? <p className='text-danger small_text'>{passErr}</p>
                                    : ''
                            }
                        </FormGroup> */}
                        <p className='small_text'>* Required fields</p>
                        <Button color='success' block>Upload</Button>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default CreateUserForm